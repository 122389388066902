import './App.css';
function Menu(props) {

    const resetPage = props.resetLoad;

    return (
        props.loaded?
        <div>
        <div className="header no-print">
          <div className="left" style={{fontSize:"0.9rem"}}>&nbsp;</div>
          <div className="content">Testercise</div>
          <div className="right">&nbsp;</div>
      </div>
      <div className="menu no-print">
          <div className="left" style={{fontSize:"0.9rem"}}>&nbsp;</div>
          <div className="content">
          <a href={"/testercise"} onClick={resetPage}>Testercise</a></div>
          <div className="right"><a href={"#"}>About</a></div>
      </div>
      <div className="no-print" style={{
        paddingTop:"15vh",
        display: "flex",
        justifyContent: "center",
        flexDirection: "row"
      }}>
          <div className="left" style={{fontSize:"0.9rem"}}>&nbsp;</div>
          <div className="content">Test, test, and test some more.</div>
          <div className="right">&nbsp;</div>
      </div>
      </div>:""
      );
    }

export default Menu;