import './App.css';
function Footer(props) {

    return (
        props.loaded?
        <div>
        <div>&nbsp;</div>
    <div>&nbsp;</div>
    <div>&nbsp;</div>
    <div className="container" style={{paddingTop:"5vh"}}>
            <div className="left">&nbsp;</div>
            <div className="content" style={{textAlign:"center",fontSize:"0.75rem"}}>Copyright &#169; {new Date().getFullYear()}</div>
            <div className="right">&nbsp;</div>
        </div>
      </div>:""
      );
    }

export default Footer;